<template>
    <div>
        <div class="flex flex-wrap gap-3" v-if="!this.submitted">
            <div class="h-auto min-h-fit rounded-lg p-3 rounded-lg flex-grow">
                <div
                    @drop.prevent="dropFile"
                    @dragenter="dropFile"
                    @dragover="dropFile"
                    :class="{ ' bg-pink-800': dragActive }"
                    style="height: 280px"
                    class="flex items-center justify-center w-full px-12 py-8 border-4 border-gray-300 border-dashed rounded dark:border-2"
                >
                    <div
                        class="flex flex-col items-center justify-center gap-1 w-full text-white dark:text-gray-100 py-2"
                    >
                        <label
                            for="file"
                            class="w-auto flex items-center hover:bg-gray-900 bg-red-500 px-5 py-2 rounded-full shadow-lg border cursor-pointer text-white my-auto font-medium text-[22px] gap-2"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25px"
                                height="25px"
                                viewBox="0 0 24 24"
                                fill="none"
                                data-v-5d608623=""
                            >
                                <g
                                    clip-path="url(#clip0_429_10970)"
                                    data-v-5d608623=""
                                >
                                    <circle
                                        cx="12"
                                        cy="11.999"
                                        r="9"
                                        stroke="#FFFFFF"
                                        stroke-width="2.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        data-v-5d608623=""
                                    ></circle>
                                    <path
                                        d="M12 9V15"
                                        stroke="#FFFFFF"
                                        stroke-width="2.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        data-v-5d608623=""
                                    ></path>
                                    <path
                                        d="M9 12H15"
                                        stroke="#FFFFFF"
                                        stroke-width="2.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        data-v-5d608623=""
                                    ></path>
                                </g>
                                <defs data-v-5d608623="">
                                    <clipPath
                                        id="clip0_429_10970"
                                        data-v-5d608623=""
                                    >
                                        <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                            data-v-5d608623=""
                                        ></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                            <span class="">Upload your HEIC Files</span>
                            <input
                                type="file"
                                multiple
                                name="fields"
                                @change="handleFileSelection"
                                ref="file"
                                accept=".heic"
                                id="file"
                                class="hidden"
                            />
                        </label>
                        <p
                            class="text-base md:text-xl mt-2.5 text-gray-400 font-semibold text-black"
                        >
                            Or drop files here.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex w-full" v-if="this.files.length && !this.submitted">
            <div class="flex flex-wrap mt-4 rounded-lg p-4 w-full">
                <div class="w-full flex justify-between">
                    <h3 class="my-auto ml-4">
                        Images({{ this.files.length }})
                    </h3>
                   
                </div>
                <div
                    class="w-full p-3 my-1 flex flex-wrap flex-row"
                    v-for="(file, index) in files"
                >
                    <div
                        class="w-full flex flex-wrap flex-row justify-between gap-2 border-2 border-gray-200 rounded-md p-2 relative"
                    >
                        <div
                            class="w-full flex flex-wrap flex-row justify-between"
                        >
                            <div class="flex flex-wrap gap-2">
                                <img
                                    :src="file.dataURL"
                                    class="rounded-lg my-2 h-5 mx-auto object-contain"
                                    style="
                                        object-fit: cover;
                                        width: 60px;
                                        height: 55px;
                                    "
                                />
                                <div class="flex flex-col my-auto">
                                    <span
                                        class="text-xs font-semibold text-red-600 my-2"
                                        >{{
                                            file.name.substr(
                                                file.name.length - 10
                                            )
                                        }}</span
                                    >
                                    <span class="text-red-600">{{
                                        Math.floor(file.file.size / 1024) + "KB"
                                    }}</span>
                                </div>
                            </div>
                            <div class="flex">
                                <p class="my-auto text-base font-semibold mx-2">
                                    Output
                                </p>
                                <select
                                    id="select"
                                    class="w-[77px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hover:bg-red-50 cursor-pointer"
                                    disabled
                                >
                                    <option value="PDF">JPG</option>
                                </select>
                                <button
                                @click="CropperPopupSet(index)"
                                title="Cropper"
                                class="inline-flex items-center top-1 right-1 my-auto"
                                >
                                <div
                                    class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50"
                                >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="#000000"
                                            width="22px"
                                            height="22px"
                                            viewBox="0 0 1920 1920"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M426.667 0h106.666v1386.67H1920v106.66H426.667V0zM320 426.667H0v106.666h320V426.667zm320 0v106.666h746.67V1280h106.66V426.667H640zM1493.33 1600h-106.66v320h106.66v-320z"
                                            />
                                        </svg>
                                    </div>
                                    </button>
                              
                                    <button
                                        @click="settingPopup = true"
                                        title="Setting"
                                    >
                                    <div
                                    class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50"
                                >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="22px"
                                            height="22px"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                                stroke="#292D32"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
                                                stroke="#292D32"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    </button>
                                
                                    <button
                                        @click="remove(files.indexOf(file))"
                                        title="Remove file"
                                        class="inline-flex items-center top-1 right-1 my-auto"
                                    >
                                    <div
                                    class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50"
                                >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            fill="red"
                                            height="13px"
                                            width="13px"
                                            version="1.1"
                                            id="Layer_1"
                                            viewBox="0 0 492 492"
                                            xml:space="preserve"
                                        >
                                            <g>
                                                <g>
                                                    <path
                                                        d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- convet button start -->
        <div class="w-full flex flex-wrap flex-row justify-end px-2">
            <div class="flex flex-row">
                <div class="mx-2">
                    <button
                        v-if="files.length > 0 && !conversionCompleted"
                        @click="uploadFiles"
                        :disabled="disableConvertButton"
                        :class="{
                            'bg-red-300': disableConvertButton,
                            'bg-red-800': !disableConvertButton,
                            'hover:bg-red-500 hover:shadow-sm':
                                !disableConvertButton,
                        }"
                        class="py-2 px-3 me-2 mb-2 text-sm font-medium text-white border rounded-md focus:outline-none bg-red-800 hover:bg-red-400 focus:ring-4 focus:ring-gray-100"
                    >
                        Convert
                        <i class="fa-solid fa-arrow-right"></i>
                    </button>
                </div>
            </div>
        </div>
        <!-- convert button end -->
        <div v-if="!this.submitted">
            <div class="inline-flex items-center justify-center w-full px-4">
                <hr
                    class="w-full h-px my-5 bg-gray-200 border-0 dark:bg-gray-700"
                />
                <span
                    class="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900"
                    >or</span
                >
            </div>

            <div class="px-4">
                <p class="text-base md:text-xl font-semibold text-black mb-2">
                    Import from URL
                </p>
                <form>
                    <label
                        for="search"
                        class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                        >Search</label
                    >
                    <div class="relative">
                        <input
                            type="text"
                            v-model="fileUrl"
                            id="upload"
                            class="block w-full p-4 ps-10 pe-24  truncate text-sm text-gray-900 border border-gray-300 rounded-lg bg-red-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-red-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Add file URL"
                        />
                        <button
                            type="submit"
                            @click="handleUpload"
                            v-if="fileUrl"
                            class="text-black absolute end-2.5 bottom-2.5 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Upload
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div>
            <div
                class="flex flex-wrap shadow-lg mt-4 rounded-lg p-4"
                v-if="this.files.length && this.submitted"
            >
                <div class="w-full flex justify-between">
                    <h3 class="my-auto ml-5">
                        Results({{ this.files.length }})
                    </h3>
                    <div>
                        <button
                            @click="removeAllFiles"
                            type="button"
                            class="py-2.5 px-3 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-red-600 focus:ring-4 focus:ring-gray-100"
                        >
                            Cancel All
                        </button>
                    </div>
                </div>
                <div
                    class="flex flex-wrap justify-between w-full p-3 my-1"
                    v-for="(file, index) in files"
                    :key="index"
                >
                    <div
                        class="flex flex-wrap flex-row w-full justify-between gap-2 border-2 border-gray-200 rounded-md p-2"
                    >
                        <div class="flex flex-wrap gap-2">
                            <img
                                :src="file.dataURL"
                                class="rounded-lg h-5 my-1 mx-auto object-contain"
                                style="
                                    object-fit: cover;
                                    width: 60px;
                                    height: 55px;
                                "
                            />
                            <div class="flex flex-col my-auto">
                                <span
                                    class="text-xs font-semibold text-red-600 my-1"
                                    >{{
                                        file.name.substr(file.name.length - 10)
                                    }}</span
                                >
                                <span class="text-red-600">{{
                                    Math.floor(file.file.size / 1024) + "KB"
                                }}</span>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="my-auto">
                                <a
                                    v-if="file.uploaded"
                                    target="_blank"
                                    :href="file.dataURL"
                                    :download="file.name"
                                    class="w-full bg-red-800 p-1 mt-1 text-white rounded hover:bg-red-500 hover:shadow-sm"
                                    >Download</a
                                >
                            </div>
                            <div
                                class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50"
                            >
                                <button
                                    @click="remove(files.indexOf(file))"
                                    title="Remove file"
                                    class="inline-flex items-center top-2 right-1"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        fill="red"
                                        height="13px"
                                        width="13px"
                                        version="1.1"
                                        id="Layer_1"
                                        viewBox="0 0 492 492"
                                        xml:space="preserve"
                                    >
                                        <g>
                                            <g>
                                                <path
                                                    d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- download all button -->
                 <div class="w-full flex justify-end mr-12 mt-2">
                    <button
                        v-if="conversionCompleted && files.length >1"
                        @click="downloadAllAsZip"
                        class="py-1.5 px-2 me-2 mb-2 text-sm font-medium text-white border rounded-md focus:outline-none bg-red-700 hover:bg-red-400 focus:ring-4 focus:ring-gray-100"
                    >
                        Download All
                    </button>
                 </div>
            </div>
        </div>
        <!-- option pop up start.. -->
        <div
            v-if="settingPopup"
            class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        >
            <div class="bg-white p-4 rounded-lg w-[720px] relative">
                <div class="flex my-4">
                    <!--cross button..-->
                    <button
                        @click="settingPopup = false"
                        class="inline-flex items-center top-1 right-1 my-auto absolute top-4 right-4 mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full  justify-center hover:bg-red-50"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            fill="red"
                            height="13px"
                            width="13px"
                            version="1.1"
                            id="Layer_1"
                            viewBox="0 0 492 492"
                            xml:space="preserve"
                        >
                            <g>
                                <g>
                                    <path
                                        d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"
                                    />
                                </g>
                            </g>
                        </svg>
                    </button>

                    <div
                        class="my-auto mx-2 w-10 h-10 rounded-md border-2 flex items-center justify-center"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22px"
                            height="22px"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                stroke="#292D32"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
                                stroke="#292D32"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <div class="mx-2">
                        <p class="text-lg font-semibold">Options</p>
                        <span class="text-sm font-medium"
                            >JFIF to JPG Converter</span
                        >
                    </div>
                </div>
                <hr />
                <div class="my-5">
                    <div class="mb-1 flex justify-around">
                        <label class="my-auto block text-black dark:text-white">
                            Quality <span class="text-meta-1">*</span>
                        </label>
                        <!--                        <input v-model="quality" type="number" id="quality" name="quality" class="w-2/3 mr-2 rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" min="0" max="1" step="1" />-->

                        <select v-model="quality" class="w-2/3">
                            <option value="1">High</option>
                            <option value="0.5">Medium</option>
                            <option value="0">Low</option>
                        </select>
                    </div>
                    <div class="w-[66%] text-left ml-[26%] mb-1">
                        <span class="text-[12px] leading-normal">
                            JPEg compression level from 1 (lowest image quality
                            and highest compression) to 100 (best quality but
                            least effective compression). The default is to
                            estimate the quality based on your input image.
                        </span>
                    </div>
                </div>
                <hr />
                <div class="w-[90%] mx-auto">
                    <button
                        @click="settingPopup = false"
                        class="w-[46%] hover:bg-red-50 mt-4 px-4 mx-2 py-1.5 text-black border-2 rounded-lg cursor-pointer"
                    >
                        Cancel
                    </button>
                    <button
                    @click="settingPopup=false"
                        class="w-[46%] mt-4 px-4 py-1.5 bg-red-800 text-white rounded-lg cursor-pointer hover:bg-red-600"
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
        <!-- option pop up end.. -->

        <!-- cropper Pop up start.. -->
        <div
            v-if="cropperPopup"
            class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 flex flex-col"
        >
            <div class="w-[720px] relative">
                <button
                    class="inline-flex items-center my-auto absolute top-5 right-5 z-50 px-2 py-2 rounded bg-white"
                    @click="cropperPopup = false"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        fill="red"
                        height="13px"
                        width="13px"
                        version="1.1"
                        id="Layer_1"
                        viewBox="0 0 492 492"
                        xml:space="preserve"
                    >
                        <g>
                            <g>
                                <path
                                    d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"
                                />
                            </g>
                        </g>
                    </svg>
                </button>
                <div class="bg-white p-4 rounded-lg w-[720px] relative">
                    <VuePictureCropper
                        :aspectRatio="1 / 1"
                        :boxStyle="{
                            width: '100%',
                            height: '500px',
                            backgroundColor: '#f8f8f8',
                            margin: 'auto',
                        }"
                        :img="pic"
                        :options="options"
                        @cropend="getResult"
                        ref="cropperRef"
                    />

                    <CropComponent  :isClodeModel="cropperPopup"
                @update:isClodeModel="cropperPopup = $event"  :confirmCrop="confirmCrop" :flipX="flipX" :flipY="flipY" :rotateTo="rotateTo" :reset="reset"/>
                </div>
            </div>
        </div>
        <!-- cropper Pop up end.. -->
    </div>
</template>

<script>
import { computed, ref } from "vue";
import axios from "axios";
import JSZip from "jszip";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import "cropperjs/dist/cropper.css";
import heic2any from "heic2any";
import CropComponent from "./CropComponent.vue";
export default {
    props: {
        translations: Object,
        files: Array,
    },
    components: {
        VuePictureCropper,
        CropComponent
    },
    data() {
        return {
            options: {
                viewMode: 2,
                dragMode: "crop",
                aspectRatio: NaN,
            },
            cropperPopup: false,
            pic: "",
            result: null,
            files: [],
            currentFileIndex: null,
            convertedPdfUrl: null,
            quality: 1,
        };
    },
    methods: {
        rotateTo(e) {
            cropper.rotate(e);
            // Reset last selection and results
        },

        flipX() {
            let flipp = this.flipXValue === true ? 1 : -1;
            cropper.scaleX(flipp);
            this.flipXValue = !this.flipXValue;
        },
        flipY() {
            let flipp = this.flipYValue === true ? 1 : -1;
            cropper.scaleY(flipp);
            this.flipYValue = !this.flipYValue;
        },

        getResult(result) {
            this.result = result;
        },

        reset() {
            if (!cropper) return;
            cropper.reset();
        },
    },
    setup() {
        const files = ref([]);
        const dragActive = ref(false);
        const submitted = ref(false);
        const settingPopup = ref(false);
        const cropperPopup = ref(false);
        const conversionCompleted = ref(false);
        const disableConvertButton = ref(false);
        const quality = ref(1);
        const fileUrl = ref("");
        const currentFileIndex = ref(null);
        const pic = ref("");

        const handleFileSelection = async (event) => {
            const newFiles = await Promise.all(
                Array.from(event.target.files).map(async (file) => {
                    const objectURL = URL.createObjectURL(file);

                    try {
                        const blob = await heic2any({
                            blob: file,
                            toType: "image/jpeg",
                            quality: parseFloat(quality.value),
                        });

                        const img = new Image();
                        img.src = URL.createObjectURL(blob);
                        await new Promise((resolve) => {
                            img.onload = resolve;
                        });

                        const width = img.width;
                        const height = img.height;

                        if (isNaN(width) || isNaN(height)) {
                            throw new Error("Invalid image dimensions");
                        }

                        const canvas = document.createElement("canvas");
                        canvas.width = width;
                        canvas.height = height;
                        const ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0, width, height);

                        return new Promise((resolve) => {
                            canvas.toBlob((jpegBlob) => {
                                const newFileName =
                                    file.name.replace(/\.[^/.]+$/, "") + ".jpg"; // Replace existing extension with .jpg
                                const jpgFile = new File(
                                    [jpegBlob],
                                    newFileName,
                                    {
                                        type: "image/jpeg",
                                        lastModified: new Date(),
                                    }
                                );

                                setTimeout(() => {
                                    resolve({
                                        dataURL: URL.createObjectURL(jpgFile),
                                        name: newFileName,
                                        file: jpgFile,
                                        uploading: false,
                                        uploaded: true,
                                        uploadProgress: 100,
                                        error: null,
                                    });

                                    URL.revokeObjectURL(objectURL);
                                }, 500);
                            }, "image/jpeg");
                        });
                    } catch (error) {
                        console.error("Error converting HEIC image:", error);
                        return {
                            dataURL: objectURL,
                            name: file.name,
                            file: file,
                            uploading: false,
                            uploaded: false,
                            uploadProgress: 0,
                            error: "Error converting HEIC image",
                        };
                    }
                })
            );

            files.value = newFiles;
            disableConvertButton.value = false;
        };

        const removeAllFiles = () => {
            files.value = []; // Empty the files array
            submitted.value = false;
            conversionCompleted.value = false; // Reset conversion completed flag
        };

        const uploadFiles = async () => {
            submitted.value = true;
            for (let i = 0; i < files.value.length; i++) {
                if (files.value[i].uploaded) {
                    continue;
                }
                await uploadFile(files.value[i]);
            }
            conversionCompleted.value = true;
        };

        const download = async (index) => {
            alert("download");
        };

        const downloadAllAsZip = async () => {
            const zip = new JSZip();

            for (const file of files.value) {
                const response = await fetch(file.dataURL);
                const blob = await response.blob();
                zip.file(file.name, blob);
            }

            const zipBlob = await zip.generateAsync({ type: "blob" });

            // Create a URL for the Blob
            const url = URL.createObjectURL(zipBlob);

            // Create a temporary anchor element
            const a = document.createElement("a");
            a.href = url;
            a.download = "converted_files.zip";

            // Append the anchor to the body (not visible)
            document.body.appendChild(a);

            // Programmatically click the anchor to trigger the download
            a.click();

            // Remove the anchor from the document
            document.body.removeChild(a);

            // Revoke the Blob URL to release memory
            URL.revokeObjectURL(url);
        };

        const showDownloadAllButton = computed(() => {
            const jpgFiles = files.value.filter(
                (file) =>
                    file.name.toLowerCase().endsWith(".jpg") ||
                    file.name.toLowerCase().endsWith(".jpeg")
            );
            return jpgFiles.length > 1;
        });

        const remove = async (index) => {
            files.value.splice(index, 1);
            if (!files.value.length) {
                submitted.value = false;
            }
        };

        const uploadFile = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;

            try {
                const blob = await heic2any({
                    blob: file.file,
                    toType: "image/jpeg",
                    quality: parseFloat(quality.value),
                });

                const newFileName = file.name.replace(/\.[^/.]+$/, "") + ".jpg"; // Replace existing extension with .jpg
                const jpgFile = new File([blob], newFileName, {
                    type: "image/jpeg",
                    lastModified: new Date(),
                });
                const objectURL = URL.createObjectURL(jpgFile);

                setTimeout(() => {
                    file.uploadProgress = 100;
                    file.uploading = false;
                    file.uploaded = true;

                    file.dataURL = URL.createObjectURL(jpgFile);
                    file.name = newFileName;
                    URL.revokeObjectURL(objectURL);
                }, 500);
            } catch (error) {
                file.uploading = false;
                file.error = "Error converting HEIC image";
            }
        };

        const confirmCrop = async () => {
            const dataURL = cropper.getDataURL();
            console.log("Cropped Image Data URL:", dataURL);

            // Update the Data URL and the file object in the files array
            const croppedBlob = dataURLtoBlob(dataURL);
            const newFileName =
                files.value[currentFileIndex.value].name.replace(
                    /\.[^/.]+$/,
                    ""
                ) + "_cropped.jpg";

            files.value[currentFileIndex.value].dataURL = dataURL;
            files.value[currentFileIndex.value].file = new File(
                [croppedBlob],
                newFileName,
                { type: croppedBlob.type }
            );
            files.value[currentFileIndex.value].name = newFileName;

            cropperPopup.value = false;
        };

        const dataURLtoBlob = (dataURL) => {
            const byteString = atob(dataURL.split(",")[1]);
            const mimeString = dataURL
                .split(",")[0]
                .split(":")[1]
                .split(";")[0];

            const arrayBuffer = new ArrayBuffer(byteString.length);
            const intArray = new Uint8Array(arrayBuffer);

            for (let i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }

            return new Blob([intArray], { type: mimeString });
        };

        const CropperPopupSet = (index) => {
            currentFileIndex.value = index;
            cropperPopup.value = true;
            pic.value = files.value[currentFileIndex.value].dataURL;
            console.log(files.value[currentFileIndex.value].dataURL);
        };

        const uploadFileOld = async (file) => {
            const formData = new FormData();
            formData.append("convert", "to-jpg");
            formData.append("images", file.file); // Append the actual File object

            const config = {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );
                    file.uploading = true;
                    file.uploadProgress = progress;
                },
            };

            try {
                const response = await axios.post(
                    "/file-upload-convert",
                    formData,
                    config
                );
                file.uploading = false;
                file.uploaded = true;
                file.uploadProgress = 100;
                file.responseData = response.data;
                file.downloadPath = response.data.path;
            } catch (error) {
                file.uploading = false;
                file.error = "Error uploading image";
                console.error("Error uploading image:", error);
            }
        };

        const dragover = async () => {
            dragActive.value = true;
        };
        const dragleave = async (event) => {
            dragActive.value = false;
        };
        const handleUpload = async (event) => {
            event.preventDefault();
            const isImage = await checkImageURL(fileUrl.value);
            if (!isImage) {
                alert("Invalid format: Only image URLs are allowed.");
                return;
            }

            try {
                const response = await fetch(fileUrl.value);
                if (!response.ok) {
                    console.error(
                        "Error:",
                        response.status,
                        response.statusText
                    );
                    alert("Error fetching image from the URL.");
                    return;
                }

                const blob = await response.blob();
                const file = new File(
                    [blob],
                    fileUrl.value.substring(fileUrl.value.lastIndexOf("/") + 1),
                    { type: blob.type }
                );

                const imageUrl = URL.createObjectURL(blob);
                const fileObject = {
                    dataURL: imageUrl,
                    name: file.name,
                    type: blob.type,
                    uploading: false,
                    uploaded: false,
                    uploadProgress: 0,
                    error: null,
                    file: file,
                };
                files.value.push(fileObject);
                disableConvertButton.value = false;
                fileUrl.value = "";
            } catch (error) {
                console.error("Error handling image upload:", error);
            }
        };

        const checkImageURL = async (url) => {
            console.log("Checking image URL:", url);
            const response = await fetch(url);
            console.log("Fetch response:", response);
            if (!response.ok) {
                console.error("Error:", response.status, response.statusText);
                throw new Error("Failed to fetch image.");
            }

            const contentType = response.headers.get("content-type");
            console.log("Content-Type:", contentType);
            if (
                contentType &&
                (contentType.startsWith("image/") ||
                    contentType.startsWith("img/"))
            ) {
                const imageData = await response.blob();
                console.log("Image Data:", imageData);
                if (imageData && imageData.type.startsWith("image/")) {
                    console.log("Image URL is valid.");
                    return true;
                } else {
                    throw new Error("Response data is not an image.");
                }
            } else {
                throw new Error("Invalid content type for image.");
            }
        };

        const dropFile = (event) => {
            event.preventDefault();
            dragActive.value = false;
            const newFiles = Array.from(event.dataTransfer.files).map(
                (file) => ({
                    dataURL: URL.createObjectURL(file),
                    name: file.name,
                    uploading: false,
                    uploaded: false,
                    uploadProgress: 0,
                    error: null,
                    file: file, // Store the actual File object
                })
            );

            files.value.push(...newFiles);
        };
        return {
            dragover,
            dragleave,
            dropFile,
            remove,
            dragActive,
            submitted,
            files,
            handleFileSelection,
            uploadFiles,
            quality,
            settingPopup,
            cropperPopup,
            download,
            downloadAllAsZip,
            conversionCompleted,
            showDownloadAllButton,
            removeAllFiles,
            disableConvertButton,
            fileUrl,
            handleUpload,
            CropperPopupSet,
            confirmCrop,
            pic,
        };
    },
};
</script>
<style scoped>
.flex-grow {
    flex-grow: 1;
}
</style>
